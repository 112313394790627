
main { min-height: calc(100vh - 360px);
	/*margin-bottom: 100px;
    padding-bottom: 100px;*/
}

input.ng-invalid, select.ng-invalid {border: 2px solid #851e5e}

/* accessibility link to main content */
.skip-link {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

.skip-link:focus {
	position: static;
	width: auto;
	height: auto;
}